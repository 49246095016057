.onboardingBreadCrumbs {
  margin-left: 20px;
  padding: 10px;
  cursor: pointer;
  background: #e6e5e5;
}

.onboardingBreadCrumbs:hover:not(.active-bc) {
  background: #ccc;
  color: #000;
}

.active-bc {
  background: green;
  color: white;
}

input[type="radio"] {
  transform: scale(1.75);
  border-radius: 0;
  margin-right: 10px !important;
}

input[type="checkbox"] {
  transform: scale(1.75);
  border-radius: 0;
  margin-right: 10px !important;
}
