html,
body,
body > #root,
body > #root > div {
  min-height: 100%;
}

.bottomProfileSwitch {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* height: 50px; */
  padding: 5px;
  background: pink;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-reactroot] {
  height: 100% !important;
}

/* body > #root > div {
  height: 100vh;
} */

.mp-source {
  border-radius: 2px !important;
  border: 1px solid #00adf7;
}

.fade-enter {
  z-index: 1;
  height: 0;
}
.fade-enter-active {
  height: 70%;
  transition: height 500ms ease;
}
.fade-enter-done {
  height: 70%;
}

.fade-exit {
  height: 70%;
}
.fade-exit.fade-exit-active {
  height: 0;
  transition: height 500ms ease;
}

.scans-table > tbody > tr > td {
  padding: 10px;
  border-bottom: 1px solid #000;
}

.scans-table > tbody > tr > th {
  font-weight: bold;
  color: #000;
  padding: 10px;
  border-bottom: 1px solid #000;
}

#mp-scan-history {
  /* background: #cccaca; */
  background: #8f90ca;
  color: white;
  width: 100%;
  position: absolute;
  z-index: 99;
  overflow-y: auto;
  /* padding: 20px; */
}

#scan-history {
  /* background: #cccaca; */
  background: #fb8037;
  color: white;
  width: 100%;
  position: absolute;
  z-index: 99;
  overflow-y: auto;
  /* padding: 20px; */
}
.scans-list {
  padding: 20px;
}

.progress {
  background-color: #aaadb0 !important;
  max-width: 100%;
}
/* BookScouter InfoBoxes*/
.bs-prices-container {
  display: inline-block;
  background: #2d2d2d;
  border: 1px solid #2d2d2d;
  margin-bottom: 20px;
}
.bs-price-ib {
  display: inline-block;
  /* padding: 0 7px 0 7px; */
  /* padding: 9px 6px;
  padding-bottom: 2px;
  padding-right: 0; */
  padding: 9px 9px 2px 9px;
  cursor: pointer;
}
.bs-price-ib:hover {
  background: #ccc;
}
.bs-price-ib:not(:last-child) {
  border-right: 1px solid #2d2d2d;
}
.asin-title-container {
  /* border: 1px solid #2d2d2d; */
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  box-shadow: 2px 2px 5px;
  background: #fff;
  margin-bottom: 20px;
}
.asin-title-image-container {
  /* border-right: 1px solid #ccc; */
  padding: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.asin-title-main-container {
  width: 100%;
  /* border-left: 1px solid #2d2d2d; */
}
.item-meta {
  text-align: center;
  margin-bottom: 10px;
  background: #6f42c1;
  color: #fff;
  padding: 10px;
  display: none;
}
.lp-actions {
  width: 30px;
  padding: 0 10px 0 10px;
}
.lp-actions:hover {
  background: white;
}

.input-text-lp-name {
  border: none;
  font-size: 24px;
  border-radius: 0;
}

.input-text-lp-name:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.price-range-indicator {
  position: relative;
  top: -164%;
  height: 50px;
  width: 4px;
  background: black;
}

.current-price-indicator {
  background: black;
  color: white;
  width: 49px;
  position: relative;
  left: -20px;
  font-size: 13px;
  padding: 2px;
  top: -23px;
}

.visHid {
  visibility: hidden;
}

.dot-connected {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  margin: 12px 10px 0 0;
  display: inline-block;
}

.dot-disconnected {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  margin: 12px 10px 0 0;
  display: inline-block;
}

.titleAndDecision {
  /* width: 800px; */
  max-width: 100%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 0px;
}
.titleAndDecisionInvalid {
  font-weight: bold;
  color: #fff;
  width: 800px;
  margin: 0 auto;
  background: #333333;
  padding: 15px;
  text-align: center;
  font-size: 20px;
}

.show_sales_rank {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: 5px;
  border-radius: 0px;
  text-align: center;
  background: #333333;
  color: var(--l2primary);
  padding: 10px;
  font-weight: bold;
  font-size: var(--l1fontsize);
}

.no-right-margin {
  margin-right: 0 !important;
}

#ig_asin {
  width: '50%';
  margin: '0 auto';
}

.container-cats {
  text-align: center;
  background: var(--l1tertiary);
  color: var(--l2primary);
  padding: 10px;
  font-weight: bold;
  font-size: var(--l1fontsize);
}

@media only screen and (min-width: 767px) {
  .hide-mobile {
    display: block;
  }
  .show-mobile {
    display: none;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}

.acceptItem {
  color: var(--l2primary);
}

.rejectItem {
  background: #f20050;
  color: #fff;
}
.pendingDecision {
  background: #6c757d;
  color: var(--l2primary);
}

.itemInfoRow {
  width: 1024px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 100%;
}

.itemInfo {
  min-height: 150px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background: #dee2e6;
  padding-top: 15px;
  margin-top: 10px;
}

.itemInfoHeading {
  font-size: 15px;
  padding-bottom: 4px;
  color: #6c757d;
}

.itemInfoValue {
  font-weight: 600;
  font-size: 18px;
}

.itemInfoValueCA {
  font-weight: 600;
  font-size: 18px;
  color: #646b71;
}

.countryTag {
  color: black;
}

.marginT15 {
  margin-top: 20px;
}

#asinInputBox {
  text-align: left;
  font-size: 23px;
  border: 1px solid #00adf7;
  color: var(--l1primary);
  width: 50vh;
  padding-left: 15px;
  background: #f8f9fa;
}

.onboardingInputs {
  text-align: left;
  font-size: 16px;
  padding: 20px;
  border: 1px solid #00adf7;
  color: var(--l1primary);
  width: 50vh;
  margin: 0 auto;
  text-align: center;
  background: #f8f9fa;
}

.inputboxBatchSettings {
  text-align: left;
  font-size: 15px;
  border-radius: 2px;
  border: 1px solid #00adf7;
  color: #000 !important;
  padding: 22px 15px 22px 15px;
  background: #f8f9fa;
}
.inputboxBatchSettings:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
  background: #f8f9fa;
}

.row-center {
  text-align: center;
}

.col-center {
  margin: 0 auto;
}

.row-infoBoxes {
  padding-bottom: 15px;
  margin: 5px auto;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.infoBoxes-container {
  /* position: absolute;
  left: 44%;
  transform: translateX(-39%); */
  text-align: center;
  width: 100%;
}

.infoBoxes {
  background: #333333;
  text-align: center;
  padding: 10px 0;
  padding-bottom: 0;
  display: inline-block;
  width: 95px;
  margin: 5px;
  cursor: pointer;
}

.infoBoxesBS {
  background: #333333;
  text-align: center;
  padding: 10px 10px;
  padding-bottom: 0;
  display: inline-block;
  width: auto;
  margin: 5px;
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.infoBoxes .infoHeading {
  font-size: 14px;
  color: #cccccc;
  font-weight: bold;
}

.infoBoxes .infoValue {
  font-size: 18px;
  color: #ffffff !important;
}

.bsinfo:hover {
  color: blue !important;
}

.col-half-offset {
  margin-left: 4.166666667%;
}

.infobox-bookscouter {
  text-decoration: none;
}

.keyboard-selection {
  display: none;
}

.amz_yes {
  width: 100%;
  height: 7px;
  background: #00743f;
}

.bsInfoClick {
  width: 100%;
  height: 7px;
  background: #007bff;
}

.amz_no {
  width: 100%;
  height: 7px;
  background: #f20050;
}

.infoBoxesNull {
  background: #333333;
  text-align: center;
  padding: 10px 0;
  padding-bottom: 0;
  margin: 5px;
  display: inline-block;
  width: 95px;
  cursor: pointer;
}

.infoBoxesNull .infoHeading {
  font-size: 14px;
  color: #cccccc;
  font-weight: bold;
}

.infoBoxesNull .infoValue {
  font-size: 18px;
  color: #ff838f;
  font-weight: bold;
}

#asinInputBox:focus,
.mp-source:focus,
.onboardingInputs:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.finalDecision {
  background: #4f85f8;
  width: 100%;
  height: 65px;
  position: fixed;
  bottom: 0;
  left: 0;
}

#containerIG {
  /* width: 50%; */
  width: 800px;
  max-width: 100%;
  margin: 25px auto;
  padding-bottom: 0px;
}

.categoryNames {
  font-size: 14px;
  margin-right: 15px;
  color: var(--l2tertiary);
  font-weight: 500;
}

.validbuysource {
  margin: 15px 0 15px 0px;
  font-size: 22px;
  text-align: center;
}

.mt-18 {
  margin-top: 18px;
}

.mt5 {
  margin-top: 5px;
}
.ziffit {
  background: #0444bf;
  color: #fff;
}
.sbyb {
  background: #6a8a82;
  color: #fff;
}
.sb {
  background: #72a2c0;
  color: #fff;
}
.es,
.fba {
  background: #00743f;
  color: #fff;
}
.mf {
  background: #f4874b;
  color: #fff;
}
.ws {
  background: #040c0e;
  color: #fff;
}
.rms {
  background: #a7414a;
  color: #fff;
}
.brb {
  background: #212323;
  color: #fff;
}

.br {
  background: #0d3c55;
  color: #fff;
}

.bs {
  background: #a37c27;
  color: #fff;
}

.es .book-info-cats {
  border-top: 1px solid #a8deb4;
}

.rms .book-info-cats {
  border-top: 1px solid #000;
}

.ws .book-info-cats {
  border-top: 1px solid #007bff;
}
.book-info-cats {
  text-align: right;
}
.asinTitleAsin {
  text-align: left;
}

.logo-hex {
  width: 50px;
}

.button-search,
.button-search:hover {
  background-color: #00aef3 !important;
  color: var(--l2primary);
  border: none;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.button-search:active,
.button-search:visited,
.button-search:focus {
  background-color: #00aef3 !important;
}

.text-logo {
  font-size: 18px;
  margin-top: 8px;
  margin-right: 50px;
  color: #00aef3;
  font-weight: bold;
}

.is-ceiling1 label {
  padding-top: 3px;
}

.ig_cat_dd,
.Dropdown-root {
  width: auto;
}

.Dropdown-control {
  height: 100%;
  padding: 11px 52px 11px 10px !important;
  border: 1px solid #00adf7 !important;
  background: #f8f9fa !important;
}

.Dropdown-arrow {
  top: 21px !important;
}

.dalignleft-maligncenter {
  text-align: left;
  padding-left: 25px;
  color: #fff;
}

.dalignleft-maligncenter .categoryNames {
  color: #fff;
}

.decisionImageContainer {
  float: left;
  display: block;
}
.plainHyperLinks {
  color: blanchedalmond !important;
}
.modal-dialog {
  max-width: 65% !important;
}

.modal-select-asin > .modal-dialog {
  max-width: 90% !important;
}

.onlyOnMobile {
  display: none;
  font-size: 12px;
  padding-top: 5px;
  margin-top: 7px;
  border-top: 1px dashed #ddd;
}

@media only screen and (max-width: 770px) {
  .item-meta {
    display: block;
  }
  .keyboard-selection {
    display: block;
  }
  .ig_cat_dd,
  .Dropdown-root {
    width: 100%;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
  .Dropdown-placeholder {
    text-align: center;
    padding-left: 30px;
  }
  .smallFontOnMobile {
    font-size: 14px;
  }
  .onlyOnMobile {
    display: block;
  }

  .tableColMobHide {
    display: none;
  }

  .decisionImageContainer {
    display: none;
  }

  .dalignleft-maligncenter,
  .book-info-cats {
    text-align: center;
  }

  .titleAndDecisionInvalid,
  .show_sales_rank,
  .titleAndDecision {
    width: 100%;
  }
  .row-infoBoxes {
    height: auto;
    margin-top: 20px;
    padding-bottom: 0;
  }

  .infoBoxes-container {
    /* position: relative; */
    text-align: center;
    text-align: -webkit-center;
  }

  #asinInputBox {
    width: 100%;
    padding-left: 0px;
    text-align: center;
    color: var(--l1primary);
    border: none;
    border-bottom: 1px solid #3c3836;
    border-radius: 0;
  }
  #containerIG {
    width: 100%;
    margin-bottom: 0;
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .btn_asin_search {
    margin: 0 auto;
    margin-top: 10px;
    width: 100%;
  }
  .btn_asin_search button {
    border-radius: 0;
    width: 100%;
  }
  .invincible {
    display: none;
  }
  .container-cats {
    font-size: 16px;
  }
  .validbuysource {
    margin: 5px 0 5px 5px;
  }
  .asin-title-container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .titleAndDecisionInvalid,
  .show_sales_rank,
  .titleAndDecision {
    width: 100%;
  }

  #containerIG {
    /* width: 80%; */
    margin: 25px auto;
  }

  .titleAndDecision {
    text-align: center;
  }
  .itemInfo {
    margin-bottom: 20px;
  }

  .itemInfoRow {
    margin-top: 0;
  }

  .itemInfoSection {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .asin-title-container {
    width: 100%;
  }
}

.text_batchsettings:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}
