body {
  color: #fff;
  background: #000;
}

.fragment-login-piq {
  background: "#FFF !important;";
}

.desc-div-login-es {
  padding: 0;
  max-height: 100vh;
  overflow-y: "hidden";
}

.desc-div-login-piq {
  padding: 0;
  max-height: 100vh;
  overflow-y: "hidden";
  background: "#f8f9fa !important";
}

.welcome-msg-login {
  margin: 50px 0 50px 0;
}

.support-msg {
  margin-top: 50px;
  color: "#47525d";
  font-size: 14px;
}

.form-label {
  color: #cbcbcb;
}

.logo {
  max-width: 100%;
  margin-left: -5px;
}

.div-form-container {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.div-login-left {
  padding-top: 20px;
  background: #fff;
  min-height: 100vh;
}

.inputNoFormat {
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #00aef3;
  border-radius: 0;
  width: 400px;
  max-width: 100%;
  color: #333;
  padding: 0;
}

.inputNoFormat:focus {
  outline-width: 0;
  outline: none;
  box-shadow: none;
}

.lightdarknav {
  background: #6c757d !important;
}

.desc-image-piq {
  position: absolute;
  top: 20%;
  width: 100%;
  clip: rect(0px, 5000px, 100vh, 0px);
}

.desc-image-es {
  position: absolute;
  top: 0;
  width: 100%;
  clip: rect(0px, 5000px, 100vh, 0px);
}

@media only screen and (max-width: 1024px) {
  .desc-div-login-es,
  .desc-div-login-piq {
    display: none;
  }

  .welcome-msg-login {
    margin: 30px 0 50px 0;
    font-size: 14px;
  }

  .support-msg {
    margin-top: 30px;
    color: "#47525d";
    font-size: 12px;
  }
  .div-login-left {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .div-form-container {
    width: 100%;
  }
}
